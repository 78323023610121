//= require jquery.min
//= require lazyload.min
//= require masonry.pkgd.min
//= require imagesloaded.pkgd.min
//= require swiper-bundle.min
//= require_self


//----------------------------------------------------------
// アンカースクロール
//----------------------------------------------------------
function anchorScroll() {
  $('a[href*="#"]').click(function (e) {
    var id = $(this).attr("href");
    if (id === '#') return;
    if ($(id)[0]) {
      var target = $(id).offset().top;
      $('html, body').animate({
        scrollTop: target
      }, 300);
    }
    e.preventDefault();
    return false;
  });
}
//----------------------------------------------------------
// ページトップ
//----------------------------------------------------------
function setPageTop() {
  if (!$('#pagetop-fix')[0]) {
    $('body').append('<div id="pagetop-fix"><a href="javascript:void(0)"></a></div>');
  }
  var pageTop = $('#pagetop-fix a');
  pageTop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      pageTop.fadeIn();
    } else {
      pageTop.fadeOut();
    }
  });
  pageTop.click(function () {
    $('body, html').animate({
      scrollTop: 0
    }, 500, 'swing');
    return false;
  });
}

//----------------------------------------------------------
// masonry
//----------------------------------------------------------
function masonryLayout() {
  if (!$('.grid').length) return;
  var $container = $('.grid');
  $container.imagesLoaded(function(){
      $container.masonry({
        itemSelector: '.photoItem',
        columnWidth: '.photoItem',
        horizontalOrder: true
      });
      $('.photoItem img').addClass('not-loaded');
      $('.photoItem img.not-loaded').lazyload({
        effect: 'fadeIn',
        load: function() {
          $(this).removeClass("not-loaded");
          $container.masonry('layout');
        }
      });
      $('.photoItem img.not-loaded').trigger('scroll');
  });
}

//----------------------------------------------------------
// pageShare
//----------------------------------------------------------
function pageShare() {
  if(!$('.share_button')[0]) return;
  var url = $("meta[property='og:url']").attr('content');
  var title = $("meta[property='og:title']").attr('content');
 
  //twitter
  var tweet_url = 'https://twitter.com/share?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(title);
  $('.share_button .tw').attr("href", tweet_url);
 
  //facebook
  var facebook_url = 'https://www.facebook.com/share.php?u=' + url;
  $('.share_button .fb').attr("href", facebook_url);
 
  //LINE
  var line_url = 'https://social-plugins.line.me/lineit/share?url=' + encodeURIComponent(url);
  $('.share_button .line').attr("href", line_url);
}

//----------------------------------------------------------
// lazyload
//----------------------------------------------------------
function imgLazyLoad() {
  if(!$(".lazy").length) return;
  $(".lazy").lazyload();
}

//----------------------------------------------------------
// 実行
//----------------------------------------------------------
$(function () {
  //アンカースクロール
  anchorScroll();
  //ページトップ
  setPageTop();
  //masonry
  masonryLayout();
  //pageShare
  pageShare();
  //lazyload
  imgLazyLoad();
});
